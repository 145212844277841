.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.general-title{
  text-align: center;
  margin-bottom: 1em;
  font-size: 20px;
  color: rgb(34, 34, 34);
  font-weight: 500; 
}

.canvas-background{
  background-color:'#F5F5F5';
}
.fontDrawer {
  transform: scale(.8) 

}


.terminal {
  height: 350px;
  overflow-y: auto;
  background-color: #171717;
  color: #C4C4C4;
  padding: 35px 45px;
  font-size: 14px;
  line-height: 1.42;
  font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
  monospace;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.terminal__line {
  line-height: 2;
  white-space: pre-wrap;
}

.terminal__prompt {
  display: flex;
  align-items: center;
}

.terminal__prompt__label {
  flex: 0 0 auto;
  color: #F9EF00;
}
.terminal__prompt__label_error{
  flex: 0 0 auto;
  color: #f9003a;
}
.terminal__prompt__input {
  flex: 1;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: white;
}

.terminal__prompt__input input {
  flex: 1;
  width: 100%;
  background-color: transparent;
  color: white;
  border: 0;
  outline: none;
  font-size: 14px;
  line-height: 1.42;
  font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
  monospace;
}